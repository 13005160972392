.group {
  border-radius: 6px;
  border: 1px solid rgba(105, 105, 105, 0.226);
  padding: 20px;
  margin-top: 20px;
  position: relative;

  .delete-group {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.group-operator {
  border-radius: 6px;
  border: 1px solid rgba(105, 105, 105, 0.226);
  padding: 20px;
  margin-top: 20px;
}

.search-box {
  .search {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    .search-fields {
      margin-bottom: 10px;
      display: flex;
      flex-flow: row;
    }
  }
}


.search-modal {
  padding: 30px;

  .search-hint {
    background-color: rgba(85, 176, 255, 0.568);
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 20px;
    font-weight: 500;
  }
}
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0 2px;
  padding-right: 1em;

  + .dialog button {
    font-size: 12px;
  }
}

.fullwidth {
  width: 100%;
}

[class^="MuiToolbar-root"] + [style^="overflow-x: auto; position: relative;"] {
  height: calc(100vh - 270px) !important;
}

.group-by {
  display: flex;
  align-items: center;
  margin-right: 1em;

  svg {
    margin-left: 0.25em;
    height: 24px;
  }
}

.status-colors {
  display: inline-block;
  margin-left: 5px;
  li {
    display: inline-block;
    height: 7px;
    width: 12px;
    margin-left: 3px;
    background-color: #ffa000;

    &:first-child {
      background-color: #83b54a;
    }
    &:last-child {
      background-color: #dc3545;
    }
  }
}

.MuiInputBase-root.Mui-disabled {
  color: #333 !important;
}
.MuiTableRow-root {
  height: 100% !important;
}
.MuiPaper-elevation2 {

  .MTableToolbar-root-42 {
    justify-content: space-between;
    border-bottom: 2px solid #cccccc;
  }
  .MTableToolbar-actions-45 {
    display: none;
  }
  
}


@import "components/search";
@import "components/jobs";
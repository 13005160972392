.MuiTableCell-root {
  a:hover {
    color: #ffa000  !important
  }
}

.jobs {
  margin-left: 20px;
  text-transform: capitalize;
  padding: 20px;
  font-size: 14px;
  font-weight: 900 !important;
  color: #000;

  .job {
    margin-bottom: 10px;
  }
}
.MuiTable-root {
  .MuiTableRow-root {
    .MuiTableCell-root {
      &.MuiTableCell-body {
        &.MuiTableCell-paddingNone {
          &.MuiTableCell-sizeSmall {
            > div {
              margin: 0 20px;
            }

            .panel {
              margin: 0 !important;
              min-height: 100px;
              max-height: 600px;
              overflow: auto;
              display: flex;
              flex-flow: column;
            }
          }
        }
      }
    }
  }
}

table {
  th {
    width: unset !important;
  }
}
.search-box {
  display: flex;
  min-width: calc(200px + 2vmin);
  padding: 15px;
  flex-flow: column;


  button {
    margin-right: 10px;
  }
}

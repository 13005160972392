.add-url-modal {
  .MuiDialog-container {
    .MuiDialog-paper {
      background-color: #ffffff;
      box-shadow: 0px 30px 100px rgba(0, 0, 0, 0.3);
      border-radius: 17px;

      .MuiDialogContent-root {
        padding: 0;

        .is-invalid {
          fieldset {
            border-color: red !important;
          }

          .Mui-focused {
            fieldset {
              border-color: red !important;
            }
          }
        }

        .content {
          padding: 0 42px 42px 42px;

          .form-input {
            margin-top: 20px;

            .input-label {
              display: flex;
              align-items: center;
              margin-bottom: 8px;

              &.required {
                &::after {
                  content: ' *';
                  color: red;
                }
              }
            }

            input {
              padding: 14px;
            }

            .MuiInputBase-root {
              border-radius: 7px;
            }
          }
        }
      }

      .MuiDialogTitle-root {
        display: flex;
        flex-flow: column;
        position: relative;
        padding-top: 42px;

        .title-section {
          display: flex;
          flex-flow: column;
          align-items: center;
          font-style: normal;
          font-weight: bold;
          font-size: 19px;
          line-height: 110%;
          text-align: center;
          letter-spacing: 0.04em;

          .description {
            margin-top: 12px;
            font-weight: 600;
            font-style: italic;
            font-size: 12px;
            line-height: 130%;
            letter-spacing: 0.03em;
            color: #b3b3b3;
          }
        }

        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }

      .modal-footer {
        background-color: #464646;
        height: 70px;

        display: flex;
        justify-content: space-between;
        padding: 10px 20px;

        .MuiButton-textSecondary {
          color: #ffffff;

          &:hover {
            background-color: #ffffff1e;
          }
        }
      }
    }
  }
}
